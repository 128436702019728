const Navbar = () => {

   


    return (  
        <div className="h-20 w-screen bg-slate-200 flex justify-center items-center" >
            <h1 className="text-3xl">Adrenal Nhamogram</h1>
        </div>
    );
}
 
export default Navbar;